import { Modal } from '@mui/material';
import { ModalType } from 'components';
import { MEDIA_BREAKPOINTS } from 'constants/application';
import styled from 'styled-components';

interface Props {
  type: ModalType;
}

export const ModalWrapper = styled(Modal)<Props>`
  .modal-container-content-center,
  .modal-container-content-sub {
    @media (min-width: ${MEDIA_BREAKPOINTS.MEDIUM}) {
      max-width: 700px;
    }
  }
  /* Center */
  .modal-container-content-center {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow: hidden;
    width: max-content;
  }
  /* Horizontal modal */
  .modal-container-content-left,
  .modal-container-content-right {
    position: fixed;
    top: 0;
    padding: var(--item-size-5);
    height: calc(100% - (var(--item-size-5) * 2));
    width: max-content;
    max-width: 60%;
  }
  .modal-container-content-left {
    left: 0;
    right: unset;
  }
  .modal-container-content-right {
    left: unset;
    right: 0;
  }
  /* Bottom */
  .modal-container-content-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .bottom-modal-container {
    border-radius: var(--item-size-3) var(--item-size-3) 0 0;
    margin: 0 var(--item-size-4);
    width: 100%;
    height: max-content;
    max-width: 600px;
    overflow: hidden;
    header {
      padding: 0 var(--item-size-4);
    }
  }
  .bottom-modal-content {
    max-height: 55vh;
  }
  /* Sub modal */
  .modal-container-content-sub {
    position: relative;
    height: 100%;
    &,
    .modal-header-container {
      top: 0;
      left: 0;
    }
    .modal-header-container {
      position: fixed;
      width: calc(100% - (var(--item-size-5) * 2));
    }
    @media (min-width: ${MEDIA_BREAKPOINTS.MEDIUM}) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      overflow: hidden;
      height: max-content;
      width: max-content;
    }
  }
`;
