import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, TextField, InputAdornment } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  MailOutline,
  LockOpenOutlined,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { BUDDER_LOGO_TRANSPARENT } from 'constants/public.image';
import { ROUTE_NAME_MAP } from 'constants/routes';
import { emailValidator, passwordValidator } from 'utils/form';
import { SignInWrapper } from './signin.styled';

interface SignInProps {}

interface SignInForm {
  email: string;
  password: string;
}

const SignIn: FC<SignInProps> = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInForm>({
    delayError: 1000,
    mode: 'onChange',
  });

  const onSubmit = () => {};

  const handleVisibilityToggle = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <SignInWrapper data-testid="signin-page-wrapper" className="ui-bg-color-card">
      <form
        className="si-content p6 ui-display-flex ui-flex-column"
        method="POST"
        encType="text/plain"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="heading-content content-centered ui-flex-column">
          <img src={BUDDER_LOGO_TRANSPARENT.LARGE} alt="Budder logo" data-testid="page-logo" />
          <h1 data-testid="page-title" className="ui-font-jumbo mb3">
            Sign In
          </h1>
          <p data-testid="page-sub-title">Sign in for smarter Metrc management</p>
        </div>

        <div className="main-content">
          <div>
            <TextField
              {...register('email', emailValidator)}
              variant="outlined"
              label="Email address"
              type="email"
              fullWidth
              data-testid="signin-form-email-field"
              error={Boolean(errors.email)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="mt7">
            <TextField
              {...register('password', passwordValidator)}
              variant="outlined"
              label="Password"
              fullWidth
              data-testid="signin-form-password-field"
              error={Boolean(errors.password)}
              type={isPasswordVisible ? 'text' : 'password'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {isPasswordVisible ? <LockOpenOutlined /> : <LockOutlined />}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      className="password-visibility-button"
                      variant="text"
                      color="info"
                      type="button"
                      aria-label={isPasswordVisible ? 'Hide password' : 'Show password'}
                      data-testid="password-visibility-button"
                      onClick={handleVisibilityToggle}
                    >
                      {isPasswordVisible ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility className="ui-opacity-3" />
                      )}
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="reset-pass-container mt7 content-centered">
            <Link
              to={ROUTE_NAME_MAP.RESET_PASSWORD_INDEX}
              className="ui-color-primary ui-font-small ui-text-underline ui-font-weight-thick"
              rel="noopener noreferrer"
              aria-label="Navigate to sign in page"
              data-testid="reset-password-link"
            >
              Forgot your password?
            </Link>
          </div>
        </div>

        <div className="action-content pb7 content-centered ui-flex-column">
          <Button
            className="signin-button ui-height-jumbo"
            variant="contained"
            aria-label="Sign in"
            data-testid="signin-button"
            type="submit"
          >
            Sign In
          </Button>

          <div className="ui-font-small ui-display-flex mt8">
            <span>Don&#39;t have an account?</span>
            <Link
              to={ROUTE_NAME_MAP.SIGNUP_INDEX}
              className="ui-color-primary ui-text-underline ml1 ui-font-weight-thick"
              rel="noopener noreferrer"
              aria-label="Navigate to sign in page"
              data-testid="signup-link"
            >
              Sign up.
            </Link>
          </div>
        </div>
      </form>
    </SignInWrapper>
  );
};

export default SignIn;
