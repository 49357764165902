import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ROUTE_NAME_MAP } from 'constants/routes';
import { NotFoundWrapper } from './not-found.styled';

interface NotFoundProps {}

const NotFound: FC<NotFoundProps> = () => {
  return (
    <NotFoundWrapper
      data-testid="not-found-page-wrapper"
      className="ui-display-flex ui-items-center ui-justify-center"
    >
      <div className="home-page-index-content ph5 ui-display-flex ui-flex-column ui-items-center">
        <h1
          className="ui-font-xjumbo mt0 mr0 mb1 ml0 ui-font-weight-bold"
          data-testid="not-found-page-header"
        >
          <span>404.</span>
        </h1>
        <h3
          className="ui-font-jumbo m0 ui-font-weight-bold ui-text-center"
          data-testid="not-found-page-description"
        >
          <span>Oops! The page you&#39;re looking for may have been moved.</span>
        </h3>
        <Link
          to={ROUTE_NAME_MAP.HOME_INDEX}
          className="mt7 ui-self-center ui-color-secondary"
          rel="noopener noreferrer"
          aria-label="Navigate back to the home page"
          data-testid="not-found-page-home-link"
        >
          <span>Go to Home</span>
        </Link>
      </div>
    </NotFoundWrapper>
  );
};

export default NotFound;
