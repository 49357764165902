import { MEDIA_BREAKPOINTS } from 'constants/application';
import styled from 'styled-components';

export const SignupWrapper = styled.div`
  min-height: calc(100vh - (var(--item-size-6) * 2));
  position: relative;
  .su-content {
    max-width: 550px;
    margin: 0 auto;
  }
  img {
    width: 120px;
    height: 120px;
  }
  .main-content {
    margin-top: 50px;
  }
  .MuiOutlinedInput-root {
    background-color: ${(p) => p.theme.alpha};
  }
  .action-content {
    margin: auto 0 0 0;
    width: calc(100% - (var(--item-size-6) * 2));
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .continue-button {
    width: 100%;
  }
  @media (min-width: ${MEDIA_BREAKPOINTS.MEDIUM}) {
    .action-content {
      position: unset;
      width: 100%;
      padding: unset;
      margin-top: 100px;
    }
  }
`;
