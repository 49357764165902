import { RegisterOptions } from 'react-hook-form';

/**
 * @type {RegExp}
 * @example
 * // Valid email strings:
 * // - "user@example.com"
 * // - "john.doe123@example.co.uk"
 * // Invalid email strings:
 * // - "invalidemail"
 * // - "user@example"
 */
export const EMAIL_REGEX: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

/**
 * @type {RegExp}
 * @example
 * // Valid passwords:
 * // - "Password123"
 * // - "StrongPass9876"
 * // Invalid passwords:
 * // - "weak"
 * // - "noDigitsHere"
 */
export const PASSWORD_REGEX: RegExp = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

/**
 * @type {RegExp}
 * @example
 * // Valid full names:
 * // - "John Doe"
 * // - "Jane Smith"
 * // Invalid full names:
 * // - "John"
 * // - "JaneSmith"
 */
export const FULL_NAME_REGEX: RegExp = /^[A-Za-z]+ [A-Za-z]+$/;

/**
 * @type {RegExp}
 * @example
 * // Valid US phone numbers:
 * // - "123-456-7890"
 * // - "(123) 456-7890"
 * // Invalid US phone numbers:
 * // - "123456789"
 * // - "123-45-6789"
 */
export const PHONE_NUMBER_REGEX: RegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

/**
 * Validator for validating an email field.
 * @type {RegisterOptions}
 */
export const emailValidator: RegisterOptions = {
  required: true,
  pattern: EMAIL_REGEX,
};

/**
 * Validator for validating a password field.
 * @type {RegisterOptions}
 */
export const passwordValidator: RegisterOptions = {
  required: true,
  pattern: PASSWORD_REGEX,
};

/**
 * Validator for validating a full name field.
 * @type {RegisterOptions}
 */
export const fullNameValidator: RegisterOptions = {
  required: true,
  pattern: FULL_NAME_REGEX,
};

/**
 * Validator for validating a US phone number field.
 * @type {RegisterOptions}
 */
export const phoneNumberValidator: RegisterOptions = {
  required: true,
  pattern: PHONE_NUMBER_REGEX,
};

/**
 * Validator for validating a location field.
 * @type {RegisterOptions}
 */
export const locationValidator: RegisterOptions = {
  required: true,
  minLength: {
    value: 2,
    message: 'Location must be at least 2 characters long',
  },
};
