import React, { FC } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { PersonOutline, PhoneAndroidOutlined, LocationOnOutlined } from '@mui/icons-material';
import { fullNameValidator, phoneNumberValidator, locationValidator } from 'utils/form';
import { SignScreenProps } from './types';

const Information: FC<SignScreenProps> = (props) => {
  const { formState, register } = props;

  return (
    <div data-testid="signup-information">
      <h1 data-testid="page-title" className="ui-font-jumbo mb3">
        Personal Information
      </h1>
      <p data-testid="page-sub-title">
        Enter your personal information to continue signing up for Budder
      </p>

      <div className="main-content">
        <TextField
          {...register('fullName', fullNameValidator)}
          variant="outlined"
          label="Full name"
          data-testid="signup-form-fullname-field"
          placeholder="Enter your first & last name"
          type="text"
          fullWidth
          error={Boolean(formState.errors.fullName)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutline />
              </InputAdornment>
            ),
          }}
        />

        <div className="mt7">
          <TextField
            {...register('phoneNumber', phoneNumberValidator)}
            variant="outlined"
            label="Phone Number"
            data-testid="signup-form-phone-field"
            placeholder="123-456-7890"
            type="tel"
            fullWidth
            error={Boolean(formState.errors.phoneNumber)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PhoneAndroidOutlined />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="mt7">
          <TextField
            {...register('location', locationValidator)}
            variant="outlined"
            label="Location"
            placeholder="Tulsa, OK"
            type="text"
            fullWidth
            error={Boolean(formState.errors.location)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnOutlined />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Information;
