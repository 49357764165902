import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  EmailOutlined as EmailOutlinedIcon,
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  Close as CloseIcon,
  East as EastIcon,
} from '@mui/icons-material';
import { ROUTE_NAME_MAP } from 'constants/routes';
import { ILLUSTRATION } from 'constants/public.image';
import { Header, Modal } from 'components';
import { ResetPasswordWrapper } from './reset-password.styled';

interface ResetPasswordProps {}

type RecoveryMethod = 'email' | 'sms';

const ResetPassword: FC<ResetPasswordProps> = () => {
  const [recoveryMethod, setRecoveryMethod] = useState<RecoveryMethod>();

  const onMethodChange = (method?: RecoveryMethod) => {
    setRecoveryMethod(method);
  };

  const onModalClose = () => {
    onMethodChange();
  };

  return (
    <ResetPasswordWrapper data-testid="reset-page-wrapper" className="ui-bg-color-card">
      <div className="si-content p6 ui-display-flex ui-flex-column">
        <Header returnTo={ROUTE_NAME_MAP.SIGNIN_INDEX} leftIcon={<KeyboardArrowLeftIcon />} />

        <div className="heading-content mt6">
          <h1 data-testid="page-title" className="ui-font-jumbo mb3">
            Forgot Password?
          </h1>
          <p data-testid="page-sub-title">
            Select which method you&#39;d like to reset your password with.
          </p>
        </div>

        <div
          data-testid="page-content"
          className="page-content ui-display-flex ui-flex-column ui-align-center"
        >
          <div className="card-wrapper mb3">
            <button
              className="option-card email-option ui-display-flex ui-radius-common ui-bg-color-alpha p4"
              type="button"
              name="email"
              aria-label="Recovery using Email"
              data-testid="email-option"
              onClick={() => onMethodChange('email')}
            >
              <div className="card-icon ui-height-jumbo ui-width-jumbo content-centered ui-bg-color-primary ui-radius-common">
                <EmailOutlinedIcon />
              </div>
              <div className="card-main-content ml4">
                <h2
                  className="card-title ui-text-left ui-font-weight-thick ui-font-normal"
                  data-testid="email-option-title"
                >
                  Email
                </h2>
                <p
                  className="card-description ui-text-left mt3 ui-font-small ui-color-charlie-secondary"
                  data-testid="email-option-description"
                >
                  Reset password via email.
                </p>
              </div>
              <div className="card-right-icon content-centered ui-height-jumbo ui-width-jumbo">
                <KeyboardArrowRightIcon className="ui-color-charlie-secondary" />
              </div>
            </button>
          </div>

          <div className="card-wrapper">
            <button
              className="option-card sms-option ui-display-flex ui-radius-common ui-bg-color-alpha p4"
              type="button"
              name="sms"
              aria-label="Recovery using SMS"
              data-testid="sms-option"
              onClick={() => onMethodChange('sms')}
            >
              <div className="card-icon ui-height-jumbo ui-width-jumbo content-centered ui-bg-color-primary ui-radius-common">
                <ChatBubbleOutlineIcon />
              </div>
              <div className="card-main-content ml4">
                <h2
                  className="card-title ui-text-left ui-font-weight-thick ui-font-normal"
                  data-testid="sms-option-title"
                >
                  SMS
                </h2>
                <p
                  className="card-description ui-text-left mt3 ui-font-small ui-color-charlie-secondary"
                  data-testid="sms-option-description"
                >
                  Reset password via SMS.
                </p>
              </div>
              <div className="card-right-icon content-centered ui-height-jumbo ui-width-jumbo">
                <KeyboardArrowRightIcon className="ui-color-charlie-secondary" />
              </div>
            </button>
          </div>
        </div>

        <Modal type="center" open={Boolean(recoveryMethod)} onClose={onModalClose}>
          <div className="ui-bg-color-card p4">
            <div className="image-wrapper">
              <img
                src={ILLUSTRATION.MOBILE_LOCK}
                alt="Lock illustration"
                data-testid="mobile-lock-illustration"
                className="illustration-image"
              />
              <button
                type="button"
                aria-label="Resend code"
                data-testid="recovery-exit-button"
                className="content-centered ui-height-xlarge ui-width-xlarge recovery-exit-button ui-radius-common"
                onClick={onModalClose}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="modal-content mv5">
              <h3 data-testid="modal-title" className="ui-font-jumbo mv2">
                Password Sent!
              </h3>
              <p data-testid="modal-description" className="ui-color-charlie-secondary">
                We’ve sent the password to j******420@gmail.com!
              </p>
            </div>
            <div className="modal-actions">
              <Button
                variant="contained"
                color="primary"
                type="button"
                aria-label="Resend code"
                data-testid="recovery-resend-button"
                className="ui-height-jumbo resend-code-button"
              >
                <span>Resend Code</span>
                <EastIcon className="ml3" />
              </Button>
            </div>
          </div>
        </Modal>

        <div className="action-content pb7 content-centered">
          <div className="ui-font-small ui-display-flex mt8">
            <span data-testid="support-text">Need assistance?</span>
            <Link
              to="/"
              className="ui-color-primary ui-text-underline ml1 ui-font-weight-thick"
              rel="noopener noreferrer"
              aria-label="Navigate to support page"
              data-testid="support-link"
            >
              Contact customer support
            </Link>
          </div>
        </div>
      </div>
    </ResetPasswordWrapper>
  );
};

export default ResetPassword;
