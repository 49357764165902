import React, { FC, ReactNode } from 'react';
import { ApplicationTheme, GLOBAL_COLORS, THEME_MODE_COLORS } from 'constants/application-theme';
import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from 'styled-components';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { useApplicationTheme } from 'hooks';

/**
 * Global css styling
 */
const GlobalStyle = createGlobalStyle<{}>`
  :root {
    /* Color variables */
    --color-alpha: ${(p) => p.theme.alpha};
    --color-bravo: ${(p) => p.theme.bravo};
    --color-medium: ${(p) => p.theme.medium};
    --color-charlie: ${(p) => p.theme.charlie};
    --color-charlie-secondary: ${(p) => p.theme.charlieSecondary};
    --color-card: ${(p) => p.theme.card};

    --color-primary: ${GLOBAL_COLORS.primary};
    --color-secondary: ${GLOBAL_COLORS.secondary};
    --color-error: ${GLOBAL_COLORS.error};
    --color-warn: ${GLOBAL_COLORS.warn};
    --color-success: ${GLOBAL_COLORS.success};
    --color-white: ${GLOBAL_COLORS.white};
    --color-black: ${GLOBAL_COLORS.black};

    --color-modal: rgba(0, 0, 0, 0.5);

    --opacity-disabled: 0.7;

    /* Spacing variables */
    --item-size-0: 0px;
    --item-size-1: 4px;
    --item-size-2: 8px;
    --item-size-3: 12px;
    --item-size-4: 18px;
    --item-size-5: 22px;
    --item-size-6: 24px;
    --item-size-7: 28px;
    --item-size-8: 34px;
    --item-size-9: 48px;
    --item-size-10: 56px;
    --item-size-11: 64px;
    --item-size-12: 80px;
  }
  /* Icon classes */
  /* Follows the same values as APP_SIZE_TO_ITEM_SIZE in src/constants/application-theme.ts  */
  .ui-height-xsmall { height: var(--item-size-3); }
  .ui-height-small { height: var(--item-size-4); }
  .ui-height-normal { height: var(--item-size-6); }
  .ui-height-large { height: var(--item-size-8); }
  .ui-height-xlarge { height: var(--item-size-9); }
  .ui-height-jumbo { height: var(--item-size-10); }
  .ui-height-xjumbo { height: var(--item-size-12); }

  .ui-width-xsmall { width: var(--item-size-3); }
  .ui-width-small { width: var(--item-size-4); }
  .ui-width-normal { width: var(--item-size-6); }
  .ui-width-large { width: var(--item-size-8); }
  .ui-width-xlarge { width: var(--item-size-9); }
  .ui-width-jumbo { width: var(--item-size-10); }
  .ui-width-xjumbo { width: var(--item-size-12); }

  /* Color classes */
  .ui-color-primary { color: var(--color-primary); }
  .ui-bg-color-primary { background-color: var(--color-primary); }
  .ui-border-color-primary { border-color: var(--color-primary); }

  .ui-color-secondary { color: var(--color-secondary); }
  .ui-bg-color-secondary { background-color: var(--color-secondary); }
  .ui-border-color-secondary { border-color: var(--color-secondary); }

  .ui-color-error { color: var(--color-error); }
  .ui-bg-color-error { background-color: var(--color-error); }
  .ui-border-color-error { border-color: var(--color-error); }

  .ui-color-warn { color: var(--color-warn); }
  .ui-bg-color-warn { background-color: var(--color-warn); }
  .ui-border-color-warn { border-color: var(--color-warn); }

  .ui-color-success { color: var(--color-success); }
  .ui-bg-color-success { background-color: var(--color-success); }
  .ui-border-color-success { border-color: var(--color-success); }

  .ui-color-white { color: var(--color-white); }
  .ui-bg-color-white { background-color: var(--color-white); }
  .ui-border-color-white { border-color: var(--color-white); }

  .ui-color-black { color: var(--color-black); }
  .ui-bg-color-black { background-color: var(--color-black); }
  .ui-border-color-black { border-color: var(--color-black); }

  .ui-color-alpha { color: var(--color-alpha); }
  .ui-bg-color-alpha { background-color: var(--color-alpha); }
  .ui-border-color-alpha { border-color: var(--color-alpha); }

  .ui-color-bravo { color: var(--color-bravo); }
  .ui-bg-color-bravo { background-color: var(--color-bravo); }
  .ui-border-color-bravo { border-color: var(--color-bravo); }

  .ui-color-medium { color: var(--color-medium); }
  .ui-bg-color-medium { background-color: var(--color-medium); }
  .ui-border-color-medium { border-color: var(--color-medium); }

  .ui-color-charlie { color: var(--color-charlie); }
  .ui-bg-color-charlie { background-color: var(--color-charlie); }
  .ui-border-color-charlie { border-color: var(--color-charlie); }

  .ui-color-charlie-secondary { color: var(--color-charlie-secondary); }
  .ui-bg-color-charlie-secondary { background-color: var(--color-charlie-secondary); }
  .ui-border-color-charlie-secondary { border-color: var(--color-charlie-secondary); }

  .ui-color-card { color: var(--color-card); }
  .ui-bg-color-card { background-color: var(--color-card); }
  .ui-border-color-card { border-color: var(--color-card); }
`;

export interface ThemeProps {
  children: ReactNode;
}

const Theme: FC<ThemeProps> = ({ children }) => {
  const { themeMode } = useApplicationTheme();
  const themeColors = THEME_MODE_COLORS[themeMode];

  const appTheme: ApplicationTheme = {
    ...themeColors,
    themeMode,
  };

  const muiTheme = createTheme({
    palette: {
      mode: appTheme.themeMode,
      background: { default: appTheme.bravo },
      text: { primary: appTheme.charlie },
      primary: { main: GLOBAL_COLORS.primary },
      secondary: { main: GLOBAL_COLORS.secondary },
      error: { main: GLOBAL_COLORS.error },
      info: { main: appTheme.charlieSecondary },
      success: { main: GLOBAL_COLORS.success },
      warning: { main: GLOBAL_COLORS.warn },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: 16,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 15,
            textTransform: 'unset',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 15,
          },
        },
      },
    },
  });

  return (
    <StyledThemeProvider theme={appTheme}>
      <GlobalStyle />
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </StyledThemeProvider>
  );
};

export default Theme;
