import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTE_NAME_MAP } from 'constants/routes';
import { HomePage, SigninPage, SignupPage, ResetPasswordPage, NotFoundPage } from 'pages';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path={ROUTE_NAME_MAP.HOME_INDEX} index element={<HomePage />} />
      <Route path={ROUTE_NAME_MAP.SIGNUP_INDEX} element={<SignupPage />} />
      <Route path={ROUTE_NAME_MAP.SIGNIN_INDEX} element={<SigninPage />} />
      <Route path={ROUTE_NAME_MAP.RESET_PASSWORD_INDEX} element={<ResetPasswordPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
