interface QueryParams {
  [key: string]: string;
}

/**
 * Encodes a URL with the given parameters.
 * @param {string} url - The base URL to encode.
 * @param {QueryParams} params - An object representing the query parameters.
 * @returns {string} The encoded URL with the appended query parameters.
 */
export const encodeURL = (url: string, params: QueryParams): string => {
  if (Object.keys(params).length > 0) {
    const queryString = new URLSearchParams(params).toString();
    return `${url}?${queryString}`;
  }

  return url;
};

/**
 * Decodes a URL and extracts its query parameters.
 * @param {string} encodedURL - The URL to decode.
 * @returns {Object} An object containing the base URL and the decoded query parameters.
 */
export const decodeURL = (encodedURL: string): QueryParams => {
  // Check if the encodedURL contains protocol and domain parts
  const hasProtocolAndDomain = encodedURL.includes('://');
  const isPath = encodedURL[0] === '/';

  if (isPath) {
    encodedURL = window.location.origin + encodedURL;
  } else if (!hasProtocolAndDomain) {
    encodedURL = `https://${encodedURL}`;
  }

  try {
    const url = new URL(encodedURL);
    const params: QueryParams = {};

    // Iterate over each query parameter and populate the params object
    url.searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  } catch (error) {
    return {};
  }
};
