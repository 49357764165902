import React, { FC } from 'react';
import { SignScreenProps } from './types';

const ReviewStrains: FC<SignScreenProps> = () => {
  return (
    <div data-testid="signup-review-strains">
      <h1 data-testid="page-title" className="ui-font-jumbo mb3">
        Review existing Strains
      </h1>
      <p data-testid="page-sub-title">
        Below is a list of existing strains at FacilityName. You may create or delete strains as
        needed.
      </p>

      <div className="main-content" />
    </div>
  );
};

export default ReviewStrains;
