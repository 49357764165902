/**
 * Used to provide single source of truth for common route names,
 * and provide an easier path for migration and distinguishing
 * actual routes from strings that may coincidentally named like them.
 */
export const ROUTE_NAME_MAP = Object.freeze({
  HOME_INDEX: '/',
  SIGNUP_INDEX: '/signup',
  SIGNIN_INDEX: '/login',
  RESET_PASSWORD_INDEX: '/forgot-password',
});
