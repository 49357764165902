import React, { FC, useState } from 'react';
import { Button, TextField, InputAdornment } from '@mui/material';
import {
  MailOutline,
  LockOpenOutlined,
  LockOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { emailValidator, passwordValidator } from 'utils/form';
import { BUDDER_LOGO_TRANSPARENT } from 'constants/public.image';
import { SignScreenProps } from './types';

const Details: FC<SignScreenProps> = (props) => {
  const { formState, register } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState({
    password: false,
    confirmPassword: false,
  });

  const handleVisibilityToggle = (type: 'password' | 'confirmPassword') => {
    setIsPasswordVisible((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  return (
    <div data-testid="signup-details">
      <div className="heading-content content-centered ui-flex-column">
        <img src={BUDDER_LOGO_TRANSPARENT.LARGE} alt="Budder logo" data-testid="page-logo" />
        <h1 data-testid="page-title" className="ui-font-jumbo mb3">
          Sign Up For Free!
        </h1>
        <p className="ui-text-center ph3" data-testid="page-sub-title">
          Get started in less than one minute and turn your Metrc account into an intelligent chat
          bot
        </p>
      </div>

      <div className="main-content">
        <div>
          <TextField
            {...register('email', emailValidator)}
            variant="outlined"
            label="Email address"
            type="email"
            fullWidth
            placeholder="Enter your email address"
            data-testid="signin-form-email-field"
            error={Boolean(formState.errors.email)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutline />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="mt7">
          <TextField
            {...register('password', passwordValidator)}
            variant="outlined"
            label="Password"
            fullWidth
            data-testid="signin-form-password-field"
            error={Boolean(formState.errors.password)}
            type={isPasswordVisible.password ? 'text' : 'password'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {isPasswordVisible.password ? <LockOpenOutlined /> : <LockOutlined />}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    className="password-visibility-button"
                    variant="text"
                    color="info"
                    type="button"
                    aria-label={isPasswordVisible.password ? 'Hide password' : 'Show password'}
                    data-testid="password-visibility-button"
                    onClick={() => handleVisibilityToggle('password')}
                  >
                    {isPasswordVisible.password ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility className="ui-opacity-3" />
                    )}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="mt7">
          <TextField
            {...register('confirmPassword', passwordValidator)}
            variant="outlined"
            label="Confirm Password"
            fullWidth
            data-testid="signin-form-confirm-password-field"
            error={Boolean(formState.errors.confirmPassword)}
            type={isPasswordVisible.confirmPassword ? 'text' : 'password'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {isPasswordVisible.confirmPassword ? <LockOpenOutlined /> : <LockOutlined />}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    className="password-visibility-button"
                    variant="text"
                    color="info"
                    type="button"
                    aria-label={
                      isPasswordVisible.confirmPassword ? 'Hide password' : 'Show password'
                    }
                    data-testid="confirm-password-visibility-button"
                    onClick={() => handleVisibilityToggle('confirmPassword')}
                  >
                    {isPasswordVisible.confirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility className="ui-opacity-3" />
                    )}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Details;
