/**
 * Object containing paths to transparent logos.
 */
export const BUDDER_LOGO_TRANSPARENT = Object.freeze({
  NORMAL: '/assets/logo/transparent-96x96.svg',
  LARGE: '/assets/logo/transparent-180x180.svg',
  LARGER: '/assets/logo/transparent-192x192.svg',
  JUMBO: '/assets/logo/transparent-512x512.svg',
});

/**
 * Object containing paths to dark background logos.
 */
export const BUDDER_LOGO_DARK = Object.freeze({
  NORMAL: '/assets/logo/bg-dark-96x96.svg',
  LARGE: '/assets/logo/bg-dark-180x180.svg',
  LARGER: '/assets/logo/bg-dark-192x192.svg',
  JUMBO: '/assets/logo/bg-dark-512x512.svg',
});

/**
 * Object containing paths to illustration media.
 */
export const ILLUSTRATION = Object.freeze({
  MOBILE_LOCK: '/assets/illustration/mobile-lock.svg',
  MOBILE_LOCK_SUCCESS: '/assets/illustration/mobile-success.svg',
});
