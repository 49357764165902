import { STORAGE_KEYS } from 'constants/application';
import { DEFAULT_APPLICATION_THEME_STATE } from 'constants/application-theme';
import { getStorage, setStorage } from 'store/storage';
import { ApplicationThemeAction, ApplicationThemeState } from '.';

/**
 * Interface representing an action with the type and application theme state.
 */
interface Action {
  payload: any;
  type: ApplicationThemeAction;
}

/**
 * Reducer function for application theme state.
 * @param {ApplicationThemeState} state - Current state of the application theme.
 * @param {Action} action - Action object containing the type and corresponding payload.
 * @returns {ApplicationThemeState} Updated state of the application theme.
 */
const reducer = (state: ApplicationThemeState, action: Action): ApplicationThemeState => {
  const storage = getStorage(STORAGE_KEYS.THEME, 'local') || {};

  const themeState: ApplicationThemeState = {
    ...DEFAULT_APPLICATION_THEME_STATE,
    ...storage,
    ...state,
  };

  switch (action.type) {
    case 'mode':
    case 'isSystemDefault':
      (themeState as any)[action.type] = action.payload;

      setStorage(STORAGE_KEYS.THEME, themeState, 'local');
      return themeState;
    default:
      return themeState;
  }
};

export default reducer;
