import { ApplicationThemeState } from 'store/application-theme';

export type ThemeMode = 'light' | 'dark';
export type ModeColor = 'alpha' | 'bravo' | 'medium' | 'charlie' | 'charlieSecondary' | 'card';
export type GlobalColor =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warn'
  | 'success'
  | 'white'
  | 'black';
export type AppSize = 'xsmall' | 'small' | 'normal' | 'large' | 'xlarge' | 'jumbo' | 'xjumbo';
export type AppColor =
  | 'primary'
  | 'secondary'
  | 'alpha'
  | 'bravo'
  | 'medium'
  | 'charlie'
  | 'charlie-secondary'
  | 'card'
  | 'error'
  | 'warn'
  | 'success'
  | 'white'
  | 'black';

export interface ApplicationTheme extends Record<ModeColor, string> {
  themeMode: ThemeMode;
}

/**
 * Default application theme redux state.
 */
export const DEFAULT_APPLICATION_THEME_STATE: ApplicationThemeState = Object.freeze({
  mode: 'dark',
  isSystemDefault: false,
});

/**
 * Application mode colors.
 */
export const THEME_MODE_COLORS: Record<ThemeMode, Record<ModeColor, string>> = Object.freeze({
  light: {
    alpha: 'rgb(240, 240, 240)',
    bravo: 'rgb(30, 40, 50)',
    medium: 'rgb(60, 70, 80)',
    charlie: 'rgb(15, 15, 15)',
    charlieSecondary: 'rgb(242, 231, 220)',
    card: 'rgb(205, 195, 185)',
  },
  dark: {
    alpha: 'rgb(5, 8, 21)',
    bravo: 'rgb(15, 15, 15)',
    medium: 'rgb(50, 50, 50)',
    charlie: 'rgb(225, 225, 225)',
    charlieSecondary: 'rgb(200, 200, 200)',
    card: 'rgb(41, 41, 55)',
  },
});

/**
 * Application global colors.
 */
export const GLOBAL_COLORS: Record<GlobalColor, string> = Object.freeze({
  primary: 'rgb(74, 89, 255)',
  secondary: 'rgb(255, 206, 74)',
  error: 'rgb(199, 0, 57)',
  warn: 'rgb(255, 204, 0)',
  success: 'rgb(75, 181, 67)',
  white: 'rgb(255, 255, 255)',
  black: 'rgb(0, 0, 0)',
});

export const APP_SIZE_TO_ITEM_SIZE: Record<AppSize, number> = Object.freeze({
  xsmall: 3,
  small: 4,
  normal: 6,
  large: 8,
  xlarge: 9,
  jumbo: 10,
  xjumbo: 12,
});
