import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { ApplicationThemeState, setApplicationTheme } from 'store/application-theme';
import { ThemeMode } from 'constants/application-theme';

/**
 * Custom React hook for accessing application theme-related state and actions.
 * @returns Object containing theme
 */
const Hook = () => {
  const reduxState: ApplicationThemeState = useSelector((state: RootState) => state.theme);
  const dispatch = useDispatch();

  const modeCleaned: ThemeMode = reduxState.mode === 'light' ? 'light' : 'dark';
  const isSystemDark = window.matchMedia('(prefers-color-scheme: dark)')?.matches;
  const systemModePreference: ThemeMode = isSystemDark ? 'dark' : 'light';

  return {
    themeMode: reduxState.isSystemDefault ? systemModePreference : modeCleaned,
    isSystemDefault: reduxState.isSystemDefault,
    setThemeMode: (mode: ThemeMode) => dispatch(setApplicationTheme.mode(mode)),
    setIsSystemDefault: (isDefault: boolean) => {
      if (isDefault) {
        dispatch(setApplicationTheme.mode(systemModePreference));
      }
      dispatch(setApplicationTheme.isSystemDefault(isDefault));
    },
  };
};

export default Hook;
