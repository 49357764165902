import React, { FC } from 'react';
import { SignScreenProps } from './types';

const DryingRoom: FC<SignScreenProps> = () => {
  return (
    <div data-testid="signup-drying-room">
      <h1 data-testid="page-title" className="ui-font-jumbo mb3">
        Drying Room
      </h1>
      <p data-testid="page-sub-title">Which room is used to dry harvested plants?</p>

      <div className="main-content" />
    </div>
  );
};

export default DryingRoom;
