import React, { FC } from 'react';
import { SignScreenProps } from './types';

const Operations: FC<SignScreenProps> = () => {
  return (
    <div data-testid="signup-operations">
      <h1 data-testid="page-title" className="ui-font-jumbo mb3">
        Which states do you operate in?
      </h1>
      <p data-testid="page-sub-title">
        Enter your personal information to continue signing up for Budder
      </p>

      <div className="main-content" />
    </div>
  );
};

export default Operations;
