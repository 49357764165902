import { ThemeMode } from 'constants/application-theme';

/**
 * Application theme action type.
 */
export type ApplicationThemeAction = 'mode' | 'isSystemDefault';

/**
 * Application theme enum.
 */
export enum ApplicationThemeEnum {
  MODE = 'mode',
  IS_SYSTEM_DEFAULT = 'isSystemDefault',
}

/**
 * Application theme state interface.
 */
export interface ApplicationThemeState {
  mode: ThemeMode;
  isSystemDefault: boolean;
}

/**
 * Action creator for application theme actions.
 * @template T - The type of the action.
 * @param {T} type - The type of the action.
 * @returns {ActionCreator<T>} The action creator function.
 */
type ActionCreator<T extends ApplicationThemeAction> = (payload: any) => {
  type: T;
  payload: any;
};

/**
 * Object containing action creators for application theme actions.
 */
export const setApplicationTheme: Record<
  ApplicationThemeAction,
  ActionCreator<ApplicationThemeAction>
> = {
  /**
   * Action creator for setting the theme mode.
   * @param mode - The mode to set.
   * @returns Action object with type and mode.
   */
  mode: (mode: ThemeMode) => ({ type: 'mode', payload: mode }),
  /**
   * Action creator for setting is System default.
   * @param isDefault - The boolean to set.
   * @returns Action object with type and size.
   */
  isSystemDefault: (isDefault: boolean) => ({
    type: 'isSystemDefault',
    payload: isDefault,
  }),
};
