import React, { FC, lazy, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { BUDDER_LOGO_TRANSPARENT } from 'constants/public.image';
import { ROUTE_NAME_MAP } from 'constants/routes';
import { decodeURL, encodeURL } from 'utils/url';
import { Loader } from 'components';
import { HomeWrapper } from './home.styled';

const WelcomeModal = lazy(() => import('./welcome-modal/welcome-modal'));

interface HomeProps {}

const STEP_LIMITS = {
  MIN: 1,
  MAX: 4,
};

const Home: FC<HomeProps> = () => {
  const [currentStep, setCurrentStep] = useState(STEP_LIMITS.MIN);

  const navigate = useNavigate();
  const location = useLocation();
  const decodedQuery = decodeURL(location.pathname + location.search);

  const handleBackClick = () => {
    if (currentStep === STEP_LIMITS.MIN) {
      navigate(ROUTE_NAME_MAP.HOME_INDEX);
      return;
    }

    setCurrentStep((prev) => prev - 1);
  };

  const onNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <HomeWrapper data-testid="home-page-wrapper">
      <div className="wp-content p8 ui-display-flex ui-flex-column" data-testid="wp-content">
        <div className="logo-container">
          <img
            src={BUDDER_LOGO_TRANSPARENT.LARGE}
            alt="Budder logo"
            data-testid="welcome-page-logo"
            className="budder-logo"
          />
        </div>
        <div className="content-container">
          <header className="page-header">
            <h1 data-testid="welcome-page-title" className="mb3">
              Budder
            </h1>
            <h3 data-testid="welcome-page-sub_title">Simplifying cannabis compliance.</h3>
          </header>

          <main className="pt9 mt3">
            <div className="illustration mb9 pb9">
              <img
                src="https://framerusercontent.com/images/rd46ndwsTJbXBUZ7oX177JX1pmc.png"
                alt="illustration"
                data-testid="illustration-image"
                className="illustration-image"
              />
            </div>
            <div className="action-container p8">
              <Link
                to={encodeURL(ROUTE_NAME_MAP.HOME_INDEX, { welcome: 'true' })}
                className="get-started-link ui-bg-color-primary content-centered ui-height-jumbo ui-font-weight-thick"
                rel="noopener noreferrer"
                aria-label="Navigate to welcome page"
                data-testid="get-started-link"
              >
                Get Started
              </Link>

              <div className="ui-font-small content-centered mt8">
                <span>Already have an account?</span>
                <Link
                  to={ROUTE_NAME_MAP.SIGNIN_INDEX}
                  className="ui-color-primary ui-text-underline ml1 ui-font-weight-thick"
                  rel="noopener noreferrer"
                  aria-label="Navigate to sign in page"
                  data-testid="signin-link"
                >
                  Sign in.
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>

      {decodedQuery?.welcome && (
        <Loader
          spinner={{
            thickness: 10,
          }}
          size="xjumbo"
          background="modal"
        >
          <WelcomeModal
            currentStep={currentStep}
            minStep={STEP_LIMITS.MIN}
            maxStep={STEP_LIMITS.MAX}
            onClose={() => navigate(ROUTE_NAME_MAP.HOME_INDEX)}
            handleBackClick={handleBackClick}
            onNextStep={onNextStep}
          />
        </Loader>
      )}
    </HomeWrapper>
  );
};

export default Home;
