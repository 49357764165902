import React, { FC } from 'react';
import { SignScreenProps } from './types';

const ReviewItems: FC<SignScreenProps> = () => {
  return (
    <div data-testid="signup-review-items">
      <h1 data-testid="page-title" className="ui-font-jumbo mb3">
        Review existing Items
      </h1>
      <p data-testid="page-sub-title">
        Some of your strains currently do not have items, please review & make any necessary changes
      </p>

      <div className="main-content" />
    </div>
  );
};

export default ReviewItems;
