import React, { FC, ReactNode, ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import { East as EastIcon, West as WestIcon, Close as CloseIcon } from '@mui/icons-material';
import { HeaderWrapper } from './header.styled';

export interface HeaderProps extends ComponentProps<'header'> {
  left?: string | ReactNode;
  onBackHandler?: () => void;
  returnTo?: string;
  leftIcon?: 'arrow' | 'x' | ReactNode;
  title?: string;
  right?: string | ReactNode;
  onNextHandler?: () => void;
  to?: string;
  rightIcon?: ReactNode;
}

const Header: FC<HeaderProps> = (props) => {
  const {
    left,
    onBackHandler,
    returnTo,
    leftIcon,
    children,
    title,
    right,
    onNextHandler,
    to,
    rightIcon,
    ...rest
  } = props;

  let leftWidget;
  let leftIconWidget;
  let mainWidget;
  let rightWidget;
  let rightIconWidget;

  if (typeof leftIcon === 'object') {
    leftIconWidget = leftIcon;
  } else {
    const icon = leftIcon === 'x' ? <CloseIcon /> : <WestIcon />;
    leftIconWidget = typeof left === 'string' ? left : icon;
  }

  if (typeof rightIcon === 'object') {
    rightIconWidget = rightIcon;
  } else {
    rightIconWidget = typeof right === 'string' ? right : <EastIcon />;
  }

  if (typeof left === 'object') {
    leftWidget = left;
  } else if (onBackHandler) {
    leftWidget = (
      <button
        className="content-centered header-return-button mr6"
        type="button"
        aria-label="Return"
        data-testid="header-return-button"
        onClick={onBackHandler}
      >
        {leftIconWidget}
      </button>
    );
  } else if (returnTo) {
    leftWidget = (
      <Link
        to={returnTo}
        className="content-centered header-return-link mr6"
        rel="noopener noreferrer"
        aria-label="Return"
        data-testid="header-return-link"
      >
        {leftIconWidget}
      </Link>
    );
  }

  if (children) {
    mainWidget = children;
  } else if (title) {
    mainWidget = <h1 className="ui-font-weight-thick ui-font-xlarge">{title}</h1>;
  }

  if (typeof right === 'object') {
    rightWidget = right;
  } else if (onNextHandler) {
    rightWidget = (
      <button
        className="content-centered header-next-button ml6"
        type="button"
        aria-label="Next"
        data-testid="header-next-button"
        onClick={onNextHandler}
      >
        {rightIconWidget}
      </button>
    );
  } else if (to) {
    rightWidget = (
      <Link
        to={to}
        className="content-centered header-next-link ml6"
        rel="noopener noreferrer"
        aria-label="Next"
        data-testid="header-next-link"
      >
        {rightIconWidget}
      </Link>
    );
  }

  return (
    <HeaderWrapper
      data-testid="header"
      className="ui-display-flex ui-items-center ui-justify-between ui-height-jumbo"
      {...rest}
    >
      {leftWidget && leftWidget}
      {mainWidget && mainWidget}
      {rightWidget && rightWidget}
    </HeaderWrapper>
  );
};

export default Header;
