import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { decodeURL, encodeURL } from 'utils/url';
import { ROUTE_NAME_MAP } from 'constants/routes';
import { Header } from 'components';
import { Button } from '@mui/material';
import { East as EastIcon } from '@mui/icons-material';
import {
  DetailsStep,
  InformationStep,
  OperationsStep,
  LicensesStep,
  LicensesDetailsStep,
  MembersStep,
  ReviewLocationsStep,
  DryingRoomStep,
  ReviewStrainsStep,
  ReviewItemsStep,
} from './screens';
import { SignScreenProps, SignupForm } from './screens/types';
import { SignupWrapper } from './signup.styled';

interface SignupProps {}

const queryArr = [
  '',
  'information',
  'operations',
  'licenses',
  'licenses-details',
  'members',
  'review-locations',
  'drying-room',
  'review-strains',
  'review-items',
];

const stepQueryToStepNumber: Record<string, number> = {
  '': 1,
  information: 2,
  operations: 3,
  licenses: 4,
  'licenses-details': 5,
  members: 6,
  'review-locations': 7,
  'drying-room': 8,
  'review-strains': 9,
  'review-items': 10,
};

const steps: Record<number, React.FC<SignScreenProps>> = {
  1: DetailsStep,
  2: InformationStep,
  3: OperationsStep,
  4: LicensesStep,
  5: LicensesDetailsStep,
  6: MembersStep,
  7: ReviewLocationsStep,
  8: DryingRoomStep,
  9: ReviewStrainsStep,
  10: ReviewItemsStep,
};

const Signup: FC<SignupProps> = () => {
  const { register, handleSubmit, formState } = useForm<SignupForm>({
    delayError: 1000,
    mode: 'onChange',
  });

  const location = useLocation();
  const navigate = useNavigate();

  const decodedQuery = decodeURL(location.pathname + location.search);
  const currentStep = stepQueryToStepNumber[decodedQuery.step] || 1;
  const prevStepPath =
    currentStep > 2 ? encodeURL(location.pathname, { step: queryArr[currentStep - 2] }) : undefined;
  const nextStepPath = encodeURL(location.pathname, {
    step: queryArr[currentStep],
  });

  const onNext = () => {
    if (currentStep < 10) {
      navigate(nextStepPath);
    }
    // submit
  };

  const ContentComponent = steps[currentStep];

  return (
    <SignupWrapper data-testid="signup-page-wrapper" className="ui-bg-color-card p6">
      {currentStep > 1 && <Header returnTo={prevStepPath || ROUTE_NAME_MAP.SIGNUP_INDEX} />}

      <form
        className="su-content pv3"
        method="POST"
        encType="text/plain"
        onSubmit={handleSubmit(onNext)}
      >
        <ContentComponent formState={formState} register={register} />

        <div className="action-content p6 content-centered ui-flex-column">
          <Button
            className="continue-button ui-height-jumbo content-centered"
            variant="contained"
            aria-label="Continue"
            type="submit"
            data-testid="continue-button"
          >
            <span>{currentStep === 10 ? 'Finish' : 'Next'}</span>
            {currentStep !== 10 && <EastIcon className="ml2" />}
          </Button>

          {currentStep === 1 && (
            <div className="ui-font-small ui-display-flex mt8">
              <span>Already have an account?</span>
              <Link
                to={ROUTE_NAME_MAP.SIGNIN_INDEX}
                className="ui-color-primary ui-text-underline ml1 ui-font-weight-thick"
                rel="noopener noreferrer"
                aria-label="Navigate to sign in page"
                data-testid="signin-link"
              >
                Sign in.
              </Link>
            </div>
          )}
        </div>
      </form>
    </SignupWrapper>
  );
};

export default Signup;
