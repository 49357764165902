import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  .header-next-button,
  .header-next-link {
    margin: 0 0 0 auto;
  }
  h1 {
    margin: 0 auto 0 0;
  }
  svg,
  img {
    width: var(--item-size-8);
    height: var(--item-size-8);
  }
`;
