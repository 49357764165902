import { MEDIA_BREAKPOINTS } from 'constants/application';
import styled from 'styled-components';

export const HomeWrapper = styled.div`
  .wp-content {
    min-height: calc(100vh - (var(--item-size-8) * 2));
  }
  .budder-logo {
    width: 120px;
    height: 120px;
  }
  .page-header {
    h1 {
      font-size: 50px;
    }
  }
  .heading-content {
    width: 100%;
    height: 400px;
    h1 {
      max-width: 200px;
    }
  }
  .illustration-image {
    max-width: 500px;
  }
  .action-container {
    width: calc(100% - (var(--item-size-8) * 2));
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .get-started-link {
    width: 100%;
    border-radius: 50px;
  }
  @media (min-width: ${MEDIA_BREAKPOINTS.MEDIUM}) {
    .wp-content {
      flex-direction: unset;
    }
    .budder-logo {
      width: 250px;
      height: 250px;
    }
    .page-header {
      h1 {
        font-size: 60px;
      }
      h3 {
        font-size: 30px;
      }
    }
    .logo-container {
      width: 50%;
    }
    .logo-container,
    .content-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .action-container {
      width: 100%;
      position: unset;
      padding: unset;
    }
  }
`;
