import styled from 'styled-components';

export const ResetPasswordWrapper = styled.div`
  .si-content {
    min-height: calc(100vh - (var(--item-size-6) * 2));
  }
  a {
    text-decoration: underline;
  }
  .page-content {
    margin-top: 100px;
  }
  .option-card {
    width: 100%;
    border: 2px solid transparent;
  }
  .option-card:hover {
    border-color: var(--color-primary);
  }
  .card-right-icon {
    margin: 0 0 0 auto;
  }
  .action-content {
    margin: auto 0 0 0;
    width: 100%;
  }
  .modal-container-content {
    width: 90%;
    max-width: 500px;
  }
  .image-wrapper {
    position: relative;
  }
  .recovery-exit-button {
    position: absolute;
    border: 1px solid var(--color-charlie-secondary);
    top: var(--item-size-2);
    right: var(--item-size-2);
  }
  .resend-code-button {
    width: 100%;
  }
`;
