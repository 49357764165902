import React, { FC } from 'react';
import { SignScreenProps } from './types';

const LicenseDetails: FC<SignScreenProps> = () => {
  return (
    <div data-testid="signup-licenses-details">
      <h1 data-testid="page-title" className="ui-font-jumbo mb3">
        Enter the details of your first license
      </h1>

      <div className="main-content" />
    </div>
  );
};

export default LicenseDetails;
